<template>
  <div class="usdtDealCourse">
    <div v-title data-title="虚拟币交易教程"></div>
    <div class="Navbar">
      <img @click="gotoback"
           :src="imgUrl +'left-arrow2.png'" />
      <div class="title">虚拟币交易教程</div>
    </div>

    <div class="img-box">
      <ul>
        <li @click="gotolink(1)">
          <img :src="imgUrl +'usdtDealCourse2.png'" />
        </li>
        <li @click="gotolink(2)">
          <img :src="imgUrl +'usdtDealCourse3.png'" />
        </li>
        <li @click="gotolink(3)">
          <img :src="imgUrl +'usdtDealCourse4.png'" />
        </li>
        <li @click="gotolink(4)">
          <img :src="imgUrl +'usdtDealCourse5.png'" />
        </li>
        <li @click="gotolink(5)">
          <img :src="imgUrl +'usdtDealCourse6.png'" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {};
  },
  mounted () {

  },
  methods: {
    gotoback () {
      uni.postMessage({
        data: {
          tabbarIndex: 1
        }
      });
      uni.navigateBack({});
    },

    gotolink (index) {
      if (index === 1) {
        this.$router.push({ path: "/bitcoinDeal" });
      } else if (index === 2) {
        this.$router.push({ path: "/binanceDeal" });
      } else if (index === 3) {
        this.$router.push({ path: "/mobiDeal" });
      } else if (index === 4) {
        this.$router.push({ path: "/usdtVideoCourse" });
      } else if (index === 5) {
        this.$router.push({ path: "/bitcoinVideoCourse" });
      }
    },
  },
};
</script>

<style lang="less">
body {
  background-color: #f5f5f5;
}
ul,
li {
  list-style: none;
}
.usdtDealCourse {
  .Navbar {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 43px;
    width: 100%;
    background-color: #fff;
    padding: 0 20px;
    > img {
      height: 20px;
      width: 20px;
    }
    .title {
      flex: 1;
      text-align: center;
      color: #000;
    }
  }
  .img-box {
    margin-top: 53px;
    li {
      margin: 10px 15px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
